import { ref, reactive, computed, toRaw, h } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { getTaskOverviewRegionDetail } from "@/API/rv/rvSplitTask";
import { getAllBu } from "@/API/dealerMaster";
import { TaskCategoryList } from "@/views/RetailValidation/Communication/dictionary";

const getMap = (list: any[]) => {
  return list?.reduce((prev, cur) => {
    prev[cur.value] = cur.name;
    return prev;
  }, {});
};

const useTaskDistributionOverview = () => {
  const route = useRoute();
  const { state } = useStore()
  const { status, category, id, name } = route.query;
  const originColumn = ref([
    { title: "Task Name", dataIndex: "taskName", width: 168 },
    {
      title: "Task Category",
      dataIndex: "taskCategory",
      customRender: ({ text }: any) => {
        return h("span", getMap(TaskCategoryList)[text]);
      },
      width: 168
    },
    { title: "Task Description", dataIndex: "taskDescription", width: 168 },
    { title: "Dealer Code", dataIndex: "dealerCode", width: 168 },
    { title: "SWT Code", dataIndex: "swtCode", width: 168 },
    { title: "Dealer Name CN", dataIndex: "dealerNameCN", width: 168 },
    // { title: "Sub Region", dataIndex: "subRegion", width: 168 },
    { title: "If receive data/document", dataIndex: "isReceive",slots: {customRender: 'isReceive'}, width: 168 },
    { title: "Dealer Feedback", dataIndex: "feedbackDealer", slots: {customRender: 'feedback'}, width: 168 },
  ]);
  // 根据query 过滤column
  const column = computed(() => {
    return originColumn.value.filter((item: any) => {
      return category === "2" || item.dataIndex !== "taskDescription";
    });
  });
  const query = reactive({
    bu: state.user.bu.orgnmen === 'MBPC' ? 'MB' : state.user.bu.orgnmen,
    dealerCode: "",
    dealerNameCn: "",
    headerId: id,
    subRegion: undefined,
    swtCode: "",
  });
  const dataRes = ref();
  const data = computed(() => {
    return dataRes.value ? dataRes.value : [];
  });
  const fetchTableData = () => {
    const params = { ...toRaw(query) };
    Object.keys(params).forEach((key) => {
      if (params[key] === undefined) {
        params[key] = "";
      }
    });
    return getTaskOverviewRegionDetail(params).then((res: any) => {
      dataRes.value = res;
    });
  };
  const buRes = ref();
  const buList = computed(() => {
    return buRes.value ? buRes.value : [];
  });

  const subRegions = ref([]);

  const fetchSubRegions = () => {
    getAllBu().then((res: any) => {
      res.forEach((bu: any) => {
        if (bu.nameEn === state.user.bu.orgnmen) {
          subRegions.value = bu.regionDto
        }
      })
    });
  };

  return {
    query,
    data,
    column,
    fetchTableData,
    buList,
    subRegions,
    fetchSubRegions,
    id, // headerId
    status,  // 任务状态
    category,  // 任务类型
    name // 任务名称
  };
};
export default useTaskDistributionOverview;
