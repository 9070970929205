
import { defineComponent, ref, toRefs, toRaw } from 'vue';
import TableWrapper from '@/views/Payment/components/TableWrapper.vue';
import useTaskOverviewDetail from '@/hooks/rv/communication/useTaskOverviewDetail';
import { getTaskDetailView, downloadDocuments } from '@/API/rv/rvSplitTask';
import downloadFile from '@/utils/payment/downloadFile';
export default defineComponent({
    name: 'App',
    props: {},
    setup() {
        const {
            query,
            data,
            column,
            fetchTableData,
            buList,
            subRegions,
            fetchSubRegions,
            id,
            status,
            category,
            name,
        } = useTaskOverviewDetail();
        const search = () => {
            fetchTableData();
        };
        const init = () => {
            fetchSubRegions();
            search();
        };
        init();

        const feadbackModalvisible = ref(false);
        const feadbackModalContent = ref();
        const handleView = (record: any) => {
            if (record.feedback === '2') {
                return;
            }
            getTaskDetailView(record.id).then((res: any) => {
                feadbackModalContent.value = res;
                feadbackModalvisible.value = true;
            });
        };
        const handleExport = () => {
            let params = null;
            const _query = { ...toRaw(query) };
            Object.keys(_query).forEach((key) => {
                if (_query[key] === undefined) {
                    _query[key] = '';
                }
            });
            if (category === '1') {
                params = {
                    url: `/rvapi/task/docData/download/data/taskDetail`,
                    method: 'post',
                    params: _query,
                };
            } else {
                params = {
                    url: `/rvapi/task/docData/download/doc/taskDetail`,
                    method: 'post',
                    params: _query,
                };
            }
            downloadFile(params);
        };

        const downLoad = (href: string) => {
            const downloadElement = document.createElement('a');
            downloadElement.href = href;
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
        };

        const handleDownload = () => {
            const params = {
                bu: query.bu,
                dealerCode: query.dealerCode,
                dealerNameCn: query.dealerNameCn,
                headerId: id,
                subRegion: query.dealerNameCn,
                swtCode: query.swtCode,
            };
            downloadDocuments(params).then((res: any) => {
                downLoad(res.downloadUrl);
            });
        };

        return {
            ...toRefs(query),
            column,
            data,
            buList,
            subRegions,
            search,
            handleView,
            feadbackModalvisible,
            feadbackModalContent,
            status,
            category,
            name,
            id,
            handleExport,
            handleDownload,
        };
    },
    components: {
        TableWrapper,
    },
});
